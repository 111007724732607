import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import React, { useEffect, useState, useRef, lazy, Suspense, useMemo} from 'react';
import './App.css'; // Import your CSS file for the modal styles
import { Link, V2, V3} from '@imtbl/imx-sdk';
import Notification from './notifications';
import CustomTooltip from './toolTip';
import {AssetResponse, TalentCard, Order, Asset} from './types';
import { handleRefresh } from './RefreshUtils'; 
import { openPageViewModal } from './PageViewModalUtils';

const ExperienceDisplayLazy = lazy(() => import('./experienceDisplay'));



type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};


  interface AssetModalProps {
    asset: Asset;
    saleID: string;
    onClose: () => void;
    locationPath: string;
  }


  interface ValueResponse {
    label: string;
    data: number;
  }
  


const AssetModal: React.FC<AssetModalProps> = ({ asset, saleID, onClose, locationPath }) => {

  const { t } = useTranslation();
  const [currency, setCurrency] = useState<string>(''); // Initialize with an empty string
  const [priceInUserCurrency, setPriceInUserCurrency] = useState<number | null>(null);
  const [offerAmountInETH, setOfferAmountInETH] = useState<number | null>(null);
  const [isOfferSectionVisible, setIsOfferSectionVisible] = useState(true);
  const [exchangeRates, setExchangeRates] = useState<{ USD: number; EUR: number } | null>(null);
  const [isOfferButtonVisible, setIsOfferButtonVisible] = useState(true); // State to control the visibility of the "Make an Offer" button
  const [offerAmountInCurrency, setOfferAmountInCurrency] = useState('');
  const [offerAmountForBuyersETH, setOfferAmountforBuyersETH] = useState<number | null>(null);
  const [offerAmountForBuyers, setOfferAmountforBuyers] = useState<number | null>(null);
  const [activity, setActivity] = useState<string | null>(null);
  const [tokenImage, setTokenImage] = useState<string | null>(null);
  const [isSubscriber, setIsSubscriber] = useState(false);

  

  const offerInputRef = useRef<HTMLInputElement | null>(null);

  const [notification, setNotification] = useState<{ message: string; backgroundColor?: string } | null>(null);

  const triggerNotificationWithMessage = (message: string, backgroundColor?: string) => {
    setNotification({ message, backgroundColor });
  };


const closeNotification = () => {
  setNotification(null);
};


const [valueUSD, setValueUSD] = useState<number | undefined>();
const [valueEUR, setValueEUR] = useState<number | undefined>();
const [valueETH, setValueETH] = useState<number | undefined>();


const [acceptedPayments, setAcceptedPayments] = useState('ETH_FIA');


const handleEtherumCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { checked } = event.target;

  setAcceptedPayments((prevPayments) => {
    if (checked) {
      return prevPayments.includes('FIA') ? 'ETH_FIA' : 'ETH';
    } else {
      return prevPayments.includes('FIA') ? 'FIA' : '';
    }
  });
};

const handleCardCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { checked } = event.target;

  setAcceptedPayments((prevPayments) => {
    if (checked) {
      return prevPayments.includes('ETH') ? 'ETH_FIA' : 'FIA';
    } else {
      return prevPayments.includes('ETH') ? 'ETH' : '';
    }
  });
};

const isButtonEnabled = acceptedPayments !== '';


const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  async function fetchExchangeRates() {
    try {
      // Fetch USD rate
    const usdRateString = localStorage.getItem('usdRate');
    const eurRateString = localStorage.getItem('eurRate');

    // Convert string to number using parseFloat. Use 0 or any default value as fallback if null
    const usdRate = usdRateString ? parseFloat(usdRateString) : 1;
    const eurRate = eurRateString ? parseFloat(eurRateString) : 1;

    setExchangeRates({ USD: usdRate, EUR: eurRate });

    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  }

  fetchExchangeRates();

  const pxlCurrency = localStorage.getItem('pxlCurrency');
  setCurrency(pxlCurrency?.toString()||'USD');

  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
  
}, []);



useEffect(() => {

    //console.log('valueEUR',valueEUR);
    //console.log('valueUSD',valueUSD);

    if (exchangeRates){
    const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
  
    if (currency === 'USD' && valueUSD) {
      setOfferAmountInCurrency(valueUSD?.toFixed(2));
      const AmountforBuyers = (parseFloat(valueUSD?.toFixed(2)) * 1.05).toFixed(2);
      setOfferAmountforBuyers(parseFloat(AmountforBuyers));

      const amountInETH = parseFloat((parseFloat(valueUSD?.toFixed(2)) / rate).toFixed(6));
      const amountForBuyersInETH = parseFloat((amountInETH *1.05).toFixed(6));
      setOfferAmountInETH(amountInETH);
      setOfferAmountforBuyersETH(amountForBuyersInETH);
    } else {
      // Use valueEUR if it's not undefined, otherwise fallback to a default value like 0
      const eurValue = valueEUR !== undefined ? valueEUR?.toFixed(2) : '0';
      setOfferAmountInCurrency(eurValue);
      const AmountforBuyers = (parseFloat(eurValue) * 1.05).toFixed(2);
      setOfferAmountforBuyers(parseFloat(AmountforBuyers));

      const amountInETH = parseFloat((parseFloat(eurValue) / rate).toFixed(6));
      const amountForBuyersInETH = parseFloat((amountInETH *1.05).toFixed(6));
      setOfferAmountInETH(amountInETH);
      setOfferAmountforBuyersETH(amountForBuyersInETH);
    }

  }

}, [valueEUR,valueUSD]); 

const currentHostname = window.location.href;
const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
const linkUrl = currentHostname.includes(`${stagingKey}`) 
  ? `https://${stagingKey}.peaxel.me` 
  : 'https://peaxel.me';


useEffect(() => {
  const fetchMetadata = async () => {
    try {
    
      // Update states with the fetched data
      setActivity(asset.talentCard.card_force || 'Not available');
      setTokenImage(asset.talentCard.token_new_url || null);

      setValueUSD(parseFloat(asset.talentCard.USD_value));
      setValueEUR(parseFloat(asset.talentCard.EUR_value));
      setValueETH(parseFloat(asset.talentCard.ETH_value));

    } catch (error) {
      console.error('Error fetching metadata: ', error);
      setActivity('Error fetching metadata.');
      setTokenImage('');
    }
  };

  fetchMetadata();
}, [asset]); 

console.log('asset',asset);

  
  const handleOfferInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = e.target.value;
    setOfferAmountInCurrency(inputAmount);
    const AmountforBuyers = (parseFloat(inputAmount) * 1.05).toFixed(2);
    setOfferAmountforBuyers(parseFloat(AmountforBuyers));
    //console.log('AmountforBuyers',AmountforBuyers);
    
  // Calculate the ETH value based on the exchange rate and update it
if (!isNaN(parseFloat(inputAmount)) && parseFloat(inputAmount) >= 0 && exchangeRates) {
  const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
  const amountInETH = parseFloat((parseFloat(inputAmount) / rate).toFixed(6)); // Parse as a number
  const amountForBuyersInETH = parseFloat((amountInETH *1.05).toFixed(6));
  setOfferAmountInETH(amountInETH);
  setOfferAmountforBuyersETH(amountForBuyersInETH);
  //console.log('AmountforBuyersETH',amountForBuyersInETH);
} else {
  setOfferAmountInETH(null); // Reset to null if the input is invalid
  setOfferAmountforBuyersETH(null);
}
setTimeout(() => {
  console.log('offerAmountInCurrency',offerAmountInCurrency);
  console.log('offerAmountInETH',offerAmountInETH);
}, 1000);

  };



  async function handleSellAssetModal(asset: Asset) {

    const linkIframeOptions: ConfigurableIframeOptions = {
      className: 'my-link-iframe',
    };

    console.log('offerAmountInCurrencyFin',offerAmountInCurrency);


    let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
    try {
  
      if (!isNaN(parseFloat(offerAmountInCurrency)) && parseFloat(offerAmountInCurrency) > 0) {
        if (exchangeRates) {
          const oneWeekInSeconds = 7 * 24 * 60 * 60 + 3600; // Number of seconds in a week

          const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
          const expirationTimestamp = (currentTimestamp + oneWeekInSeconds).toString();
          const rate = currency === 'USD' ? exchangeRates.USD : exchangeRates.EUR;
          const amountInETH = ((parseFloat(offerAmountInCurrency) / rate).toFixed(10)).toString();
          console.log('amountInETH',amountInETH);
          const amountinUSD = currency === 'USD' ? (parseFloat(offerAmountInCurrency)) : (parseFloat(offerAmountInCurrency))/exchangeRates.EUR*exchangeRates.USD;
          const amountinEUR = currency === 'EUR' ? (parseFloat(offerAmountInCurrency)) : (parseFloat(offerAmountInCurrency))/exchangeRates.USD*exchangeRates.EUR;
          
          console.log('valueETHalph',valueETH);
          const startValueETH = (valueETH || 0) + 0.000001;
          console.log('startValueETHalph',startValueETH);

          if (amountInETH) {
          const buyMax = 100 * parseFloat(amountInETH);
        
          
          console.log('buyMax',buyMax);
          console.log('buyMax8',buyMax.toFixed(8));
            //console.log('assetstruc',asset);
            const result = await link.sell({
            amount: buyMax.toFixed(8),
            tokenId: asset.token_id,
            tokenAddress: asset.token_address,
           /* expirationTimestamp: expirationTimestamp,*/
          });

          const currentDate = new Date();
          const currentDateTimeString = currentDate.toISOString();
          

          const registerSALE = [
            {
              ref: `${result}_SALE`,
              date_time_payment: currentDateTimeString,
              date_time_validation: '',
              sale_or_offer: 'Sale',
              order_number: result,
              offer_number: '',
              eth_value: amountInETH,
              dollar_value: amountinUSD,
              euro_value: amountinEUR,
              currency: currency,
              eth_or_cash: acceptedPayments.toString(),
              client_secret_stripe: '',
              owner: localStorage.getItem('walletAddress'),
              buyer: '',
              token_address: asset.token_address,
              token_id: asset.token_id,
              token_name: asset.name,
              status: 'inProgress',
            },
            // Add more test data as needed
          ];

          saveSalesData(registerSALE);
          triggerNotificationWithMessage(`Talent Token ${asset.name} listed for Sale`, '#20AC61');
          setTimeout(onClose, 2500);
          handleRefresh();

        }
          
          // Set the state to indicate that an offer is made
         
        } else {
          triggerNotificationWithMessage(`Something went wrong. Please try again.`, '#D31148');
        }
      } else {
        triggerNotificationWithMessage(`Something went wrong. Please try again.`, '#D31148');
      }
    } catch (error) {
        // Handle and print out the error
        triggerNotificationWithMessage(`Something went wrong. ${error}`, '#D31148')
        // Add logic to display an error message to the user, if needed.
      }
    }

    const saveSalesData = async (data: any) => {
      // Make the API request for each item in the data array
      for (const item of data) {
        try {
          const serverURL = `${linkUrl}/wp-json/custom/v1`;
    
          await fetch(`${serverURL}/sales-registry/`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(item),
          });
    
          //console.log('Data saved successfully');
        } catch (error) {
          console.error('Fetch error:', error);
        }
      }
    };


    const userCurrency = localStorage.getItem('pxlCurrency') || 'USD';
 
    useEffect(() => {

       // Add or remove the "modal-open" class on the body element
       document.body.classList.add('modal-open');
  
       // Clean up by removing the class when the modal is closed
       return () => {
         document.body.classList.remove('modal-open');
       };
      
    }, []);

  
  return (
<div className="App">
    <div className="order-modal">
      <div className="modal-content">
      <div className="product-details">
      <div className="product-image-column">
      <img width="95%" src={tokenImage || undefined} alt={asset.name} />
      </div>
      <div className="product-info-column">
      <div className="product-name">
  <span
     onClick={() => {
      openPageViewModal(asset, ''); 
      onClose(); // Close the asset modal
     // Open the page view modal
    }}
    style={{ cursor: 'pointer' }}
  >
    <i className="fa-solid fa-id-badge" style={{ color: '#93003f', fontSize: '75%', marginRight: '2px' }}></i>&nbsp;{asset.name}
  </span>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <span className="card-number">
    {asset.metadata?.token_number}/{asset.metadata?.total_tokens}
  </span>
  {saleID !== '' && (
    <>
      <span className="saleID">on sale</span>
    </>
  )}
</div>

              <div>
{notification && (
        <Notification
          message={notification.message}
          onClose={closeNotification}
          backgroundColor={notification.backgroundColor}
        />
      )}
</div>
              

  <div className="modal-div">
  <div className="product-price">

  {currency === 'USD' ? '$' : ''}{currency === 'USD' ? valueUSD?.toFixed(2) : valueEUR?.toFixed(2)}{currency === 'EUR' ? '€' : ''}&nbsp;
   <span className="order-ETH">~{valueETH?.toFixed(6)} {t('ETHAsAveragePrice')}</span>
  <br/>
  </div>



</div>


       
              <div className="modal-div">
            


  {isOfferSectionVisible && (
  <div className="sell-make-offer-tab">
    <span className="order-ETH" style={{ opacity: 0.75 }}>
      {t('setYourAskingPrice')}
    </span>
    <span className="offer-input">
      <input
        type="text"
        placeholder={t('enterOfferAmount')}
        value={`${currency === 'USD' ? '$' : ''}${offerAmountInCurrency}${currency === 'EUR' ? '€' : ''}`}
        onChange={handleOfferInputChange}
        className="offer-input"
        readOnly={false}
        ref={offerInputRef}
        style={{ width: `${(offerAmountInCurrency.length) * 10 + 150}px` }}
        onInput={(e) => {
          // Allow only numeric input and a single dot for decimals
          e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, '');
        }}
      />
    </span>
    <span className="order-ETH" style={{ opacity: 0.75 }}>
      &nbsp;&nbsp;
      <span className="order-ETH-approx">~</span>&nbsp;{offerAmountInETH !== null ? offerAmountInETH.toFixed(6) : 'Error'}&nbsp;ETH
    </span>
    <span className="buyerPrice">
    {t('buyerDisplayedPriceIncFee')}&nbsp;
    
    <CustomTooltip
    content={
      <div>
      <p><strong>{t('tradingFee')}</strong></p>
      <p style={{ opacity: 0.7 }}>{t('feesWillBeAddedOnTop')}</p>
      <ul style={{ opacity: 0.7 }}>
        <li>2% - {t('protocolsFee')}</li>
        <li>1% - {t('marketplaceFee')}</li>
        <li>1% - {t('royaltiesToTalents')}</li>
        <li>1% - {t('rewards')}</li>
      </ul>
      <p style={{ opacity: 0.7 }}>{t('feeProportionsMayVary')}</p>
    </div>
    }
    >
        <button className="button_tooltip"><i className="fa-solid fa-circle-info"></i></button>
      </CustomTooltip>
      
      <br/>
    {currency === 'USD' ? '$' : ''}{offerAmountForBuyers}{currency === 'EUR' ? '€' : ''}&nbsp;&nbsp;~{offerAmountForBuyersETH}&nbsp;ETH

    
    </span>


    <div className="checkboxesAsset">
   
    {isSubscriber === true && (
  <label>
    <input
      type="checkbox"
      name="acceptEtherum"
      defaultChecked
      onChange={handleEtherumCheckboxChange}
    />
    {t('acceptEthereumPayment')}
  </label>
)}

      <br />

      <label>
        <input
          type="checkbox"
          name="acceptCard"
          defaultChecked
          onChange={handleCardCheckboxChange}
        />
        {t('acceptCreditDebitCardPayment')}
      </label>
    </div>

    <span>
      <button
        className={`sell-make-offer ${(!offerAmountInCurrency || parseFloat(offerAmountInCurrency) === 0 || (acceptedPayments !== 'ETH' && acceptedPayments !== 'ETH_FIA' && acceptedPayments !== 'FIA')) ? 'disabled' : ''}`}
        onClick={() => handleSellAssetModal(asset)}
        disabled={!offerAmountInCurrency || parseFloat(offerAmountInCurrency) === 0 || !isButtonEnabled }
      >
        &nbsp;&nbsp;{t('listForSale')}&nbsp;&nbsp;
      </button>
    </span>
  </div>
)}


              </div>

              
              <div className="modal-div">
              <h2>{t('talentDetails')}</h2>
              <div className="info-tab">
              {/* <pre>{JSON.stringify(orderInfo, null, 2)}</pre>  Display JSON response */}
              <p className="info2">{asset?.metadata?.first_name}&nbsp;|&nbsp;{asset?.metadata?.description}</p>
              <h3>{t('mainActivity')}</h3>
              <p className="info2">{asset.talentCard.main_activity}</p>
              <h3>{t('field')}</h3>
              <p className="info2">{asset?.metadata?.field}</p>
              <h3>{t('country')}</h3>
              <p className="info2">{asset?.metadata?.country}</p>
              </div>
              </div>
              
              <div className="modal-div">
              <h2>{t('talentTokenDetails')}</h2>
              <div className="info-tab">
              <h3>{t('talentIndex')}</h3>
              <p className="info2">
  <span
    onClick={() => {
      openPageViewModal(asset, ''); 
      onClose(); // Close the asset modal
     // Open the page view modal
    }}
    style={{ cursor: 'pointer' }}

  >
    {asset.name}&nbsp;<i className="fa-solid fa-square-arrow-up-right" style={{ color: '#93003f', fontSize: '75%', marginLeft: '2px' }}></i>
  </span>
</p>
              <h3>{t('forceExperienceToken')}</h3>
              <p className="info2"> 
              <Suspense fallback={<div>Loading...</div>}>
              <ExperienceDisplayLazy
              card={asset.talentCard}
              locationXP={locationPath}
               /></Suspense></p>
              <h3>{t('tokenNumber')}</h3>
              <p className="info2">{asset.metadata?.token_number} / {asset.metadata?.total_tokens}</p>
              <h3>{t('tokenId')}</h3>
              <p className="info2">{asset.token_id}</p>
              <h3>{t('tokenAddress')}</h3>
              <p className="info2">{asset.token_address}</p>
              <h3>{t('imageUrl')}</h3>
              <p className="info2"><a href={asset.image_url} target="_blank" rel="noopener noreferrer">{asset.image_url}</a>
              </p>
              </div>
              </div>

            </div>
      </div>
      <button 
              className="close-button-modal1" 
              onClick={() => {onClose()}}>
             <i className="fa-regular fa-circle-xmark"></i>
       </button>
      </div>
    </div>
  </div>
  );


  
};

export default AssetModal;